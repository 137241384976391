$color-primary: rgba(48, 64, 100, 1);
$color-primary-dark: rgba(30, 40, 63, 1);
$color-primary-light: rgba(98, 121, 173, 1);
$color-secondary: rgba(83, 182, 164, 1);

$color-background: rgba(238, 243, 249, 1);
$color-background-system-selector: rgba(239, 242, 247, 1);
$color-text-green:rgba(20, 165, 122, 1);
$color-text-black:rgba(32, 32, 32, 1);
$color-text-primary: rgba(51, 51, 51, 1);
$color-text-secondary: rgba(130, 130, 130, 1);
$color-text-link: rgba(47, 128, 237, 1);
$color-input-border: rgba(206, 212, 218, 1);

$color-white: rgba(255, 255, 255, 1);
$color-gray: rgba(125, 125, 125, 0.22);
$color-gray-light: rgba(248, 248, 248, 1);
$color-gray-card: rgba(252, 252, 254, 1);

$color-shadow: rgba(0, 0, 0, 0.7);

$color-none: rgba(0, 0, 0, 0);
