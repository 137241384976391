@import "core-size.scss";
@import "core-color.scss";
@import "core-icn.scss";
@import "core-image.scss";
@import "core-font.scss";
@import "core-image.scss";

:host {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}