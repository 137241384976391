.image {
    background-repeat: no-repeat;
    background-size: cover;
  
    &.default-login {
      background-image: url("/assets/images/login/Default_Background.png");
      width: 100%;
      height: 100%;
    }
    &.MN {
      background-image: url("/assets/images/login/MN_Background.png");
      width: 100%;
      height: 100%;
    }
    &.LB {
      background-image: url("/assets/images/login/LB_Background.png");
      width: 100%;
      height: 100%;
    }
    &.SV {
      background-image: url("/assets/images/login/SV_Background.png");
      width: 100%;
      height: 100%;
    }
    &.VR {
      background-image: url("/assets/images/login/VR_Background.png");
      width: 100%;
      height: 100%;
    }
    &.SB {
      background-image: url("/assets/images/login/SB_Background.png");
      width: 100%;
      height: 100%;
    }
    &.DT {
      background-image: url("/assets/images/login/DT_Background.png");
      width: 100%;
      height: 100%;
    }
    &.RC {
      background-image: url("/assets/images/login/RC_Background.png");
      width: 100%;
      height: 100%;
    }
    &.background-login {
      background-image: url("/assets/images/login/background-login.png");
      width: 100%;
      height: 100%;
    }
    &.bangkok {
      background-image: url("/assets/images/home/bangkok-city.png");
      width: 100%;
      height: auto;
    }
}
  