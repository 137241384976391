.icn {
  background-repeat: no-repeat;
  background-size: cover;

  &.logo {
    background-image: url("/assets/images/logo.svg");
    width: 35px;
    height: 35px;
  }

  &.logo-login {
    background-image: url("/assets/images/login/icn_Login.png");
    width: 125px;
    height: 125px;
  }
  &.logo-login-small {
    background-image: url("/assets/images/login/icn_Login.png");
    width: 80px;
    height: 80px;
  }
  &.username {
    background-image: url("/assets/images/login/icn_Username.png");
    width: 15px;
    height: 15px;
  }
  &.password {
    background-image: url("/assets/images/login/icn_Password.png");
    width: 15px;
    height: 18px;
  }
  &.down {
    background-image: url("/assets/images/home/icn-down.svg");
    width: 8px;
    height: 5px;
  }

  &.chevron {
    background-image: url("/assets/images/home/icn-chevron.svg");
    width: 5px;
    height: 8px;
  }

  &.bma {
    background-image: url("/assets/images/bma-logo.ico");
    width: 187px;
    height: 184px;
  }

  &.arrow-green-left {
    background-image: url("/assets/images/home/arrow-green-left.png");
    width: 14px;
    height: 14px;
  }

  &.sys-DT {
    background-image: url("/assets/images/home/icn-sys-DT.png");
    width: 66px;
    height: 64px;
  }

  &.sys-LB {
    background-image: url("/assets/images/home/icn-sys-LB.png");
    width: 66px;
    height: 64px;
  }

  &.sys-MN {
    background-image: url("/assets/images/home/icn-sys-MN.png");
    width: 66px;
    height: 64px;
  }

  &.sys-RC {
    background-image: url("/assets/images/home/icn-sys-RC.png");
    width: 66px;
    height: 64px;
  }

  &.sys-SB {
    background-image: url("/assets/images/home/icn-sys-SB.png");
    width: 66px;
    height: 64px;
  }

  &.sys-SV {
    background-image: url("/assets/images/home/icn-sys-SV.png");
    width: 66px;
    height: 64px;
  }

  &.sys-UM {
    background-image: url("/assets/images/home/icn-sys-UM.png");
    width: 66px;
    height: 64px;
  }

  &.sys-VR {
    background-image: url("/assets/images/home/icn-sys-VR.png");
    width: 66px;
    height: 64px;
  }
}
