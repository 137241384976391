$font-family-EN: "NotoSans";
$font-family-TH: "NotoSansThai";

@font-face {
  font-family: NotoSans;
  src: url('../../../assets/font/NotoSans/NotoSans-Bold.ttf');
  font-weight: 700;
}

@font-face {
  font-family: NotoSans;
  src: url('../../../assets/font/NotoSans/NotoSans-Regular.ttf');
  font-weight: 400;
}

@font-face {
  font-family: NotoSansThai;
  src: url('../../../assets/font/NotoSansThai/NotoSansThai-Thin.ttf');
  font-weight: 100;
}

@font-face {
  font-family: NotoSansThai;
  src: url('../../../assets/font/NotoSansThai/NotoSansThai-ExtraLight.ttf');
  font-weight: 200;
}

@font-face {
  font-family: NotoSansThai;
  src: url('../../../assets/font/NotoSansThai/NotoSansThai-Light.ttf');
  font-weight: 300;
}

@font-face {
  font-family: NotoSansThai;
  src: url('../../../assets/font/NotoSansThai/NotoSansThai-Regular.ttf');
  font-weight: 400;
}

@font-face {
  font-family: NotoSansThai;
  src: url('../../../assets/font/NotoSansThai/NotoSansThai-Medium.ttf');
  font-weight: 500;
}

@font-face {
  font-family: NotoSansThai;
  src: url('../../../assets/font/NotoSansThai/NotoSansThai-SemiBold.ttf');
  font-weight: 600;
}

@font-face {
  font-family: NotoSansThai;
  src: url('../../../assets/font/NotoSansThai/NotoSansThai-Bold.ttf');
  font-weight: 700;
}

@font-face {
  font-family: NotoSansThai;
  src: url('../../../assets/font/NotoSansThai/NotoSansThai-ExtraBold.ttf');
  font-weight: 800;
}

@font-face {
  font-family: NotoSansThai;
  src: url('../../../assets/font/NotoSansThai/NotoSansThai-Black.ttf');
  font-weight: 900;
}
