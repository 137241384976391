.icn {
  background-repeat: no-repeat;
  background-size: cover;
}
.icn.logo {
  background-image: url("/assets/images/logo.svg");
  width: 35px;
  height: 35px;
}
.icn.logo-login {
  background-image: url("/assets/images/login/icn_Login.png");
  width: 125px;
  height: 125px;
}
.icn.logo-login-small {
  background-image: url("/assets/images/login/icn_Login.png");
  width: 80px;
  height: 80px;
}
.icn.username {
  background-image: url("/assets/images/login/icn_Username.png");
  width: 15px;
  height: 15px;
}
.icn.password {
  background-image: url("/assets/images/login/icn_Password.png");
  width: 15px;
  height: 18px;
}
.icn.down {
  background-image: url("/assets/images/home/icn-down.svg");
  width: 8px;
  height: 5px;
}
.icn.chevron {
  background-image: url("/assets/images/home/icn-chevron.svg");
  width: 5px;
  height: 8px;
}
.icn.bma {
  background-image: url("/assets/images/bma-logo.ico");
  width: 187px;
  height: 184px;
}
.icn.arrow-green-left {
  background-image: url("/assets/images/home/arrow-green-left.png");
  width: 14px;
  height: 14px;
}
.icn.sys-DT {
  background-image: url("/assets/images/home/icn-sys-DT.png");
  width: 66px;
  height: 64px;
}
.icn.sys-LB {
  background-image: url("/assets/images/home/icn-sys-LB.png");
  width: 66px;
  height: 64px;
}
.icn.sys-MN {
  background-image: url("/assets/images/home/icn-sys-MN.png");
  width: 66px;
  height: 64px;
}
.icn.sys-RC {
  background-image: url("/assets/images/home/icn-sys-RC.png");
  width: 66px;
  height: 64px;
}
.icn.sys-SB {
  background-image: url("/assets/images/home/icn-sys-SB.png");
  width: 66px;
  height: 64px;
}
.icn.sys-SV {
  background-image: url("/assets/images/home/icn-sys-SV.png");
  width: 66px;
  height: 64px;
}
.icn.sys-UM {
  background-image: url("/assets/images/home/icn-sys-UM.png");
  width: 66px;
  height: 64px;
}
.icn.sys-VR {
  background-image: url("/assets/images/home/icn-sys-VR.png");
  width: 66px;
  height: 64px;
}

.image {
  background-repeat: no-repeat;
  background-size: cover;
}
.image.default-login {
  background-image: url("/assets/images/login/Default_Background.png");
  width: 100%;
  height: 100%;
}
.image.MN {
  background-image: url("/assets/images/login/MN_Background.png");
  width: 100%;
  height: 100%;
}
.image.LB {
  background-image: url("/assets/images/login/LB_Background.png");
  width: 100%;
  height: 100%;
}
.image.SV {
  background-image: url("/assets/images/login/SV_Background.png");
  width: 100%;
  height: 100%;
}
.image.VR {
  background-image: url("/assets/images/login/VR_Background.png");
  width: 100%;
  height: 100%;
}
.image.SB {
  background-image: url("/assets/images/login/SB_Background.png");
  width: 100%;
  height: 100%;
}
.image.DT {
  background-image: url("/assets/images/login/DT_Background.png");
  width: 100%;
  height: 100%;
}
.image.RC {
  background-image: url("/assets/images/login/RC_Background.png");
  width: 100%;
  height: 100%;
}
.image.background-login {
  background-image: url("/assets/images/login/background-login.png");
  width: 100%;
  height: 100%;
}
.image.bangkok {
  background-image: url("/assets/images/home/bangkok-city.png");
  width: 100%;
  height: auto;
}

@font-face {
  font-family: NotoSans;
  src: url("../../../assets/font/NotoSans/NotoSans-Bold.ttf");
  font-weight: 700;
}
@font-face {
  font-family: NotoSans;
  src: url("../../../assets/font/NotoSans/NotoSans-Regular.ttf");
  font-weight: 400;
}
@font-face {
  font-family: NotoSansThai;
  src: url("../../../assets/font/NotoSansThai/NotoSansThai-Thin.ttf");
  font-weight: 100;
}
@font-face {
  font-family: NotoSansThai;
  src: url("../../../assets/font/NotoSansThai/NotoSansThai-ExtraLight.ttf");
  font-weight: 200;
}
@font-face {
  font-family: NotoSansThai;
  src: url("../../../assets/font/NotoSansThai/NotoSansThai-Light.ttf");
  font-weight: 300;
}
@font-face {
  font-family: NotoSansThai;
  src: url("../../../assets/font/NotoSansThai/NotoSansThai-Regular.ttf");
  font-weight: 400;
}
@font-face {
  font-family: NotoSansThai;
  src: url("../../../assets/font/NotoSansThai/NotoSansThai-Medium.ttf");
  font-weight: 500;
}
@font-face {
  font-family: NotoSansThai;
  src: url("../../../assets/font/NotoSansThai/NotoSansThai-SemiBold.ttf");
  font-weight: 600;
}
@font-face {
  font-family: NotoSansThai;
  src: url("../../../assets/font/NotoSansThai/NotoSansThai-Bold.ttf");
  font-weight: 700;
}
@font-face {
  font-family: NotoSansThai;
  src: url("../../../assets/font/NotoSansThai/NotoSansThai-ExtraBold.ttf");
  font-weight: 800;
}
@font-face {
  font-family: NotoSansThai;
  src: url("../../../assets/font/NotoSansThai/NotoSansThai-Black.ttf");
  font-weight: 900;
}
.image {
  background-repeat: no-repeat;
  background-size: cover;
}
.image.default-login {
  background-image: url("/assets/images/login/Default_Background.png");
  width: 100%;
  height: 100%;
}
.image.MN {
  background-image: url("/assets/images/login/MN_Background.png");
  width: 100%;
  height: 100%;
}
.image.LB {
  background-image: url("/assets/images/login/LB_Background.png");
  width: 100%;
  height: 100%;
}
.image.SV {
  background-image: url("/assets/images/login/SV_Background.png");
  width: 100%;
  height: 100%;
}
.image.VR {
  background-image: url("/assets/images/login/VR_Background.png");
  width: 100%;
  height: 100%;
}
.image.SB {
  background-image: url("/assets/images/login/SB_Background.png");
  width: 100%;
  height: 100%;
}
.image.DT {
  background-image: url("/assets/images/login/DT_Background.png");
  width: 100%;
  height: 100%;
}
.image.RC {
  background-image: url("/assets/images/login/RC_Background.png");
  width: 100%;
  height: 100%;
}
.image.background-login {
  background-image: url("/assets/images/login/background-login.png");
  width: 100%;
  height: 100%;
}
.image.bangkok {
  background-image: url("/assets/images/home/bangkok-city.png");
  width: 100%;
  height: auto;
}

:host {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

:root {
  --font-family: $font-family-TH, $font-family-EN, !important;
  --primary-color: rgba(20, 165, 122, 1) !important;
  --color-7: rgba(20, 165, 122, 1) !important;
}

html {
  position: relative;
  height: 100%;
}

body {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: 0;
  font-family: "NotoSansThai", "NotoSans", "primeicons" !important;
  color: #333333;
}

a {
  color: #333333;
}

/* scrollbar */
::-webkit-scrollbar {
  width: 5px !important;
  height: 5px !important;
  background-color: transparent !important;
}

::-webkit-scrollbar-track-piece {
  border-radius: 5px !important;
}

::-webkit-scrollbar-thumb {
  background-clip: padding-box !important;
  -webkit-border-radius: 5px !important;
  background-color: #f8f8f8 !important;
  -webkit-box-shadow: inset -1px -1px 0px rgba(125, 125, 125, 0.22), inset 1px 1px 0px rgba(125, 125, 125, 0.22) !important;
}

/* Overide PrimeNG */
.p-inputtext:enabled:focus,
.p-button:focus {
  box-shadow: none !important;
}

.p-error {
  position: absolute;
}

.p-dialog .p-dialog-footer {
  text-align: center !important;
}